/* Main containers */

.projectBrowser {
  margin-top: 20px !important;
  width: 75% !important;
}

.projectRow {
  justify-content: center !important;
  margin-top: 20px !important;
}

.projectColumn {
  width: 75% !important;
}

.projectContainer {
  width: 100%;
}

/* Columns */

.containerColumn {
  width: 100%;
  height: 50vh;
  padding: 25px 25px 25px 25px !important ;
}

.demoColumn {
  justify-content: center;
  align-items: center;
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
