.treeRow {
  width: 100%;
  height: 50%;
  justify-content: flex-end !important;
  margin-bottom: 15px;
}

.treeBrowser {
  display: flex;
  flex-direction: column;
  width: 65% !important;
  height: 100%;
  margin: 0px 25px 0px 0px !important;
  animation: fadein 2.5s;
}

.treeBrowser > .tree {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  overflow: auto !important;
  margin-left: 10px;
}

@media only screen and (max-width: 900px) {
  .treeRow {
    justify-content: center !important;
  }
  .treeBrowser {
    width: 80% !important;
  }
}
