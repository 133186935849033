.descriptionRow {
  display: flex;
  flex-direction: row;
  width: 80% !important;
  padding-right: 2px !important;
  margin-top: 5px;
  margin-left: 5px !important;
  margin-bottom: 25px !important;
}

.descriptionBrowser {
  width: 100% !important;
  flex-direction: column;
  animation: fadein 2s;
}

@media only screen and (max-width: 900px) {
  .descriptionRow {
    width: 100% !important;
    justify-content: center;
  }

  .descriptionBrowser {
    margin: 25px 5px 5px 5px !important;
  }
}

.descriptionText {
  margin: 0px 10px 0px 10px !important;
}
