.facePicture {
  border-radius: 50%;
}

.browserImage {
  width: 300px !important;
  animation: fadein 0.3s;
}

.browserFacePicture {
  margin-left: auto !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
