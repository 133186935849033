.gitCloneRow {
  display: flex;
  justify-content: center !important;
  width: 100%;
  margin-top: 5px;
}

.gitCloneSnippet {
  width: 85% !important;
  animation: fadein 3s;
}

.gitCloneSnippet > pre {
  scrollbar-width: none;
}

.gitCloneSnippet > div {
  margin-right: 5px;
}
