.iframeDemoBrowser {
  width: 90% !important;
  height: 90% !important;
}

.videoDemoBrowser {
  width: 90% !important;
  line-height: 0em !important;
}

.mobileContent {
  height: 100% !important;
  width: auto !important;
}

.videoRotating {
  animation: videoRotation 10s linear infinite;
}

@keyframes videoRotation {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.imgDemoBrowser {
  width: 90% !important;
  line-height: 0em !important;
}

.imgReversed {
  transform: scaleY(-1);
}

.demoContent {
  width: 100%;
  height: 100%;
  background-color: white;
}

.arrowButton {
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
  width: 2.5rem !important;
  min-width: auto !important;
  border-width: 2px !important;
  border-color: #fff !important;
}

@media only screen and (max-width: 720px) {
  .arrowButton {
    display: none !important;
  }
}

.leftArrow {
  margin-left: 15px;
}

.rightArrow {
  margin-right: 15px;
}

.BrainhubCarousel__container {
  display: grid;
  align-items: center;
}

.BrainhubCarousel__container,
.BrainhubCarousel,
.BrainhubCarousel__trackContainer,
.BrainhubCarousel__track {
  height: 100%;
}

li {
  line-height: unset !important;
}
