@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.message {
  word-break: normal !important;
}

.traffic > .close:hover {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.5s;
}
