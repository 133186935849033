#onTopDivider {
  margin: 0 0 10px 0 !important;
}

.onMiddleDivider {
  margin: 0px 0px 0px 0px !important;
}

#onBottomDivider {
  margin: 50px 0px 0px 0px !important;
}

.hidden {
  visibility: hidden;
}

.themeButton {
  display: flex !important;
  align-items:center !important;
  padding: 0 !important;
  width: 2.5rem !important;
  min-width: auto !important;
  border-radius: 50% !important;
  border-width: 2px !important;
}
