.goToButtonRow {
  animation: fadein 0.3s;
  margin-top: 50px;
  margin-bottom: 50px;
}

.goToButton {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: none !important;
}

.goToButton > div {
  display: flex !important;
  align-items: center;
  width: 100%;
}
