@media only screen and (max-width: 650px) {
  .lastNames {
    display: none;
  }
}

#topBarName {
  margin-left: 15px;
}

.nameText {
  animation: fadein 0.5s;
}
