.tech-icon {
  opacity: 0.7;
  margin: 5px;
  animation: fadein-techicon 0.8s;
}

@keyframes fadein-techicon {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

.tech-icon:hover {
  opacity: 1;
}

.invertColor {
  filter: invert();
}
