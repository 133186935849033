.basicDesc {
  margin-bottom: 35px;
  text-align: center;
  animation: fadein 0.5s;
}

.basicDesc > i {
  display: flex;
  justify-content: center;
  align-items: center;
}

.basicDesc > i > span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.autoHeight {
  height: auto !important;
}

.basicTag {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 5px;
}
